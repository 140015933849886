import * as Cookies from 'js-cookie';

export default {
  created() {
    const { coupon, redirect, redirectPath } = this.$route.query;
    if (coupon) Cookies.set('x-om-coupon', coupon, { expires: 3 });
    if (redirect) Cookies.set('x-om-redirect', redirect, { expires: 3 });
    if (redirectPath) Cookies.set('x-om-redirect-path', redirectPath, { expires: 3 });
  },
};
