<template lang="pug">
.brand-wrapper.better-email-check
  om-link(small @click="linkHome")
    om-logo-svg.om-logo
  .d-flex.justify-space-between.better-email-box
    img.mx-auto(
      src="@/assets/monk-warning.svg"
      @click="linkHome"
      style="height: 184px; width: 150px"
    )
    .d-flex.flex-column
      Badge.better-email-badge(:text="$t('new')" type="rounded")
      .title.mb-2.mt-2 {{ $t('betterEmail.title') }}
      .desc(v-html="$t('betterEmail.desc')")
      .email-check-label.mt-5.font-size-0--875 {{ $t('betterEmail.checkLabel') }}
      .email-input.mt-2
        template(v-if="mode === 'read'")
          span.font-weight-bold.mr-2.font-size-0--875 {{ email || getAccountEmail }}
          span.brand-link.font-size-0--875(@click="editEmail") {{ $t('change') }}
        template(v-else)
          .d-flex
            .d-flex.flex-column
              om-input#changeEmail.mr-2.change-email-wrapper(
                v-model="email"
                type="email"
                :placeholder="$t('betterEmail.placeholder')"
              )
                template(slot="error" v-if="$v.email.$error && !$v.email.required")
                  span {{ $t('requiredField') }}
                template(slot="error" v-if="$v.email.$error && !$v.email.isCoolEmail")
                  span {{ $t('invalidEmail') }}
                template(slot="error" v-if="hasEmailError")
                  span {{ $t('betterEmail.emailAlreadyExists') }}
              span.mt-1.email-alt-text(v-if="!$v.email.$error && !hasEmailError") {{ $t('betterEmail.weWillSend') }}
            .d-flex.align-items-end.mb-auto
              om-button.save.mr-2(@click="save" primary) {{ $t('save') }}
              om-button.cancel(@click="cancel" secondary) {{ $t('cancel') }}
        template(slot="error" v-if="$v.email.$error && !$v.email.required")
          span {{ $t('requiredField') }}
        template(slot="error" v-if="$v.email.$error && !$v.email.isCoolEmail")
          span {{ $t('invalidEmail') }}
      .actions.d-flex
        om-button.looks-good-btn.mr-4(
          primary
          large
          :disabled="mode === 'write'"
          @click="handleLookGood"
        ) {{ $t('looksGood') }}
        om-button.inv-team-btn(
          primary
          ghost
          large
          :disabled="mode === 'write'"
          @click="handleInvite"
        ) {{ $t('menu.inviteYourTeam') }}
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import Badge from '@/components/Badge.vue';
  import { email, required } from 'vuelidate/lib/validators';
  import CHANGE_EMAIL from '@/graphql/ChangeEmail.gql';
  import { keyInErrors } from '@/utils/error';
  import { ERROR_MESSAGE_KEY } from '@/utils/constant';
  import SET_PROFILE_KEY from '@/graphql/SetProfileKey.gql';
  import { PROFILE_KEY_BETTER_EMAIL } from '@/config/constants';
  import queryParamToCookie from '@/mixins/queryParamToCookie';

  export default {
    components: { Badge },

    mixins: [queryParamToCookie],
    data() {
      return {
        mode: 'read',
        email: '',
        hasEmailError: false,
        originalEmail: '',
      };
    },
    computed: {
      ...mapGetters(['getAccountEmail']),
    },

    methods: {
      ...mapMutations(['showAdminLoader']),
      ...mapActions(['fetchAccount']),
      async linkHome() {
        const { params, query } = this.$route;

        await this.$router.push({
          path: '/',
          params: { ...params },
          query: { ...query },
        });
      },
      async handleInvite() {
        await this.setProfile();
        await this.$router.push({ name: 'account_sharing' });
      },
      async setProfile() {
        await this.$apollo.mutate({
          mutation: SET_PROFILE_KEY,
          variables: { key: PROFILE_KEY_BETTER_EMAIL, value: 1 },
        });

        await this.fetchAccount();
      },
      async handleLookGood() {
        await this.setProfile();

        const { params, query } = this.$route;

        await this.$router.push({
          path: '/',
          params: { ...params },
          query: { ...query },
        });
      },
      editEmail() {
        this.email = this.email || this.getAccountEmail;
        this.mode = 'write';
      },
      cancel() {
        this.email = this.getAccountEmail;
        this.mode = 'read';
      },
      async save() {
        this.hasEmailError = false;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.$v.$reset();
          if (this.email === this.getAccountEmail) {
            this.mode = 'read';
            await this.$router.push('/');
            return;
          }

          try {
            this.originalEmail = this.getAccountEmail;
            const result = await this.$apollo.mutate({
              mutation: CHANGE_EMAIL,
              variables: {
                email: this.email.toLowerCase(),
              },
            });
            const { errors } = result;
            if (keyInErrors(ERROR_MESSAGE_KEY.EMAIL_ALREADY_REGISTERED, errors)) {
              this.hasEmailError = true;
              this.$notify({
                type: 'error',
                text: this.$t('betterEmail.emailAlreadyExists'),
              });
            } else if (keyInErrors(ERROR_MESSAGE_KEY.RATE_LIMIT, errors)) {
              this.$notify({
                type: 'error',
                text: this.$t('betterEmail.tryAgainLater'),
              });
            } else if (errors?.length) {
              this.this.$notify({
                type: 'error',
                text: this.$t('saveError'),
              });
            } else {
              this.hasEmailError = false;
              this.$notify({
                type: 'success',
                text: this.$t('notifications.sendChangeEmail', { email: this.originalEmail }),
              });
              this.mode = 'read';
            }
          } catch (e) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          }
        }
      },
    },
    validations: {
      email: {
        required,
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .better-email-check
    color: $om-gray-800
    padding-top: 112px
    .om-logo
      position: absolute
      top: 14px
      left: 16px
    .better-email-box
      max-width: 680px
      margin: auto
      gap: 40px
    .title
        font-size: 24px
        font-weight: 700
        line-height: 32px
    .desc
      max-width: 432px
      font-size: 18px
      line-height: 28px
    .email-check-label
      color: $om-gray-700
    .email-alt-text
      color: $om-gray-600
      font-size: 12px
      line-height: 16px
    .better-email-badge
        width: 28px
        height: 20px
    .change-email-wrapper
      width: 296px
    .email-input
      .save,.cancel
        max-height: 40px
    .actions
      margin-top: 20px
      .looks-good-btn
        max-width: 124px
        height: 52px
      .inv-team-btn
        min-width: 156px
        height: 52px
        border-radius: 3px
        border: 1px $om-orange-500 solid
      .inv-team-btn.disabled
        border: 1px $om-gray-600 solid
        color: $om-gray-600 !important
</style>
